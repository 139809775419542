<template>
  <div class="editorder teblestyle">
    <div class="c-box" style="padding-top: 10px">
      <div class="danhao">
        <div>订单号：{{ forData.orderNo }}</div>
        <div>订单状态：{{ forData.frontStatusName }}</div>
        <div>创建时间：{{ forData.createTime }}</div>
      </div>
      <el-card style="margin: 20px 0">
        <div class="infodiv">
          <div class="xinxi">
            <div class="icontit">基础信息</div>
            <div class="xinxifo">
              <div>服务名称：{{ forData.names }}</div>
              <div>货物类型：<span v-if="forData.types == 1">整柜</span><span v-if="forData.types == 2">散柜</span></div>
              <div>订柜尺寸：{{ forData.reserveSize }}</div>
              <div>起 运 港：{{ forData.startShipmentName }}</div>
            </div>
            <div class="xinxifo">
              <div>目 的 港：{{ forData.destinationPortName }}</div>
              <div>开船日期：{{ reTime(forData.sailTime) }}</div>
              <div>截仓日期：{{ reTime(forData.jcTime) }}</div>
              <div>截亏仓日期：{{ reTime(forData.jkcTime) }}</div>
            </div>
            <div class="xinxifo">
              <div>航 程：{{ forData.voyageDay }}</div>
              <div>预计到达：{{ forData.estimatedTime }}</div>
              <el-popover
                placement="top"
                title="标题"
                width="400"
                trigger="click"
                :content="forData.fillMaterialDescription"
              >
                <el-button slot="reference" type="primary" size="mini">补充资料说明</el-button>
              </el-popover>
            </div>
          </div>
        </div>
      </el-card>
      <el-card style="margin: 20px 0">
        <div class="jifeizhong">
          <div class="icontit">
            计费重信息<br>
            <el-button
              type="primary"
              round
              size="mini"
              style="width: 90px"
              @click="dialogVisible = true"
            >编辑</el-button>
          </div>

          <div class="tablediv">
            <el-table :data="forData.orderCaseVOList" stripe style="width: 100%">
              <el-table-column prop="cartonNo" label="箱号" align="center" />
              <el-table-column prop="fabNo" label="FBA箱号" align="center">
                <template slot-scope="scope">
                  <el-input v-if="forData.frontStatusCode === '9'" v-model="scope.row.fabNo" />
                </template>
              </el-table-column>
              <el-table-column prop="amazonReferenceId" label="FBA箱号" align="center">
                <template slot-scope="scope">
                  <el-input v-if="forData.frontStatusCode === '9'" v-model="scope.row.amazonReferenceId" />
                </template>
              </el-table-column>
              <el-table-column
                prop="asnWeight"
                label="预计重量（KG）"
                align="center"
              />
              <el-table-column
                prop="asnLwg"
                label="长*宽*高（cm）"
                align="center"
              />
              <el-table-column
                prop="asnVolume"
                label="预计体积(m3)"
                align="center"
              />
              <el-table-column
                prop="confirmWeight"
                label="实际重量（KG）"
                align="center"
              />
              <el-table-column
                prop="confirmVolume"
                label="实际体积(m3)"
                align="center"
              />
            </el-table>
          </div>
        </div>
      </el-card>
      <el-card style="margin: 20px 0">
        <div class="jifeizhong">
          <div class="icontit">
            商品信息
            <br>
            <el-button
              type="primary"
              round
              size="mini"
              style="width: 90px"
              @click="xuanzheshangping"
            >选择商品</el-button>
            <el-button
              type="primary"
              round
              size="mini"
              style="width: 90px"
              @click="deleteGoods"
            >移除</el-button>
          </div>
          <div class="tablediv">
            <el-table ref="multiTable" :data="forData.orderShopVOList" stripe style="width: 100%" @selection-change="handleSelectGoods">
              <el-table-column type="selection" />
              <el-table-column label="SKU" align="center">
                <template slot-scope="scope">
                  <span class="skutit" @click="skutitdetails(scope.row.id)">{{
                    scope.row.sku
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="nameCn"
                label="商品名称（中文）"
                align="center"
              />
              <el-table-column label="发货数量" align="center">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.quantity" size="mini" />
                </template>
              </el-table-column>
              <el-table-column
                prop="meiyou"
                label="每个申报价值/美元"
                align="center"
              />
              <el-table-column prop="hsCode" label="海关编码" align="center" />
            </el-table>
          </div>
        </div>
      </el-card>
      <el-card style="margin: 20px 0">
        <div class="jifeizhong">
          <div class="icontit">报关文件</div>
          <!-- <div class="filediv filelist">
            <div v-for="obj in forData.orderCustomsFileVOList" :key="obj.id">{{obj.describe}}：<a :href="obj.templateUrl">{{obj.fileName}}</a></div>
          </div> -->
          <div v-for="file in forData.orderCustomsFileVOList" :key="file.id" class="upload">
            {{ file.fileName }}：
            <span v-for="(list, i) in file.templateUrlVOS" :key="i" class="item">
              <a :href="list.absolutePath" target="_Blank">
                {{ list.fileName }}
              </a>
              <i class="el-tag__close el-icon-close" @click.stop="fileRemove(file.templateUrlVOS,i)" />
            </span>
            <el-upload
              class="upload-demo"
              action="http://test.oms.jayud.com:9448/jayudFile/file/uploadFile"
              :on-success="(response)=>downTemplate(file.templateUrlVOS,response)"
              :on-error="error"
              :with-credentials="true"
              :show-file-list="false"
            >
              <el-button type="primary" size="mini">上传</el-button>
            </el-upload>
          </div>
        </div>
      </el-card>
      <el-card style="margin: 20px 0">
        <div class="jifeizhong">
          <div class="icontit">清关文件</div>
          <!-- <div class="filediv filelist">
            <div v-for="obj in forData.orderCustomsFileVOList" :key="obj.id">{{obj.describe}}：<a :href="obj.templateUrl">{{obj.fileName}}</a></div>
          </div> -->
          <div v-for="file in forData.orderClearanceFileVOList" :key="file.id" class="upload">
            {{ file.fileName }}：
            <span v-for="(list, i) in file.templateUrlVOS" :key="i" class="item">
              <a :href="list.absolutePath" target="_Blank">
                {{ list.fileName }}
              </a>
              <i class="el-tag__close el-icon-close" @click.stop="fileRemove(file.templateUrlVOS,i)" />
            </span>
            <el-upload
              class="upload-demo"
              action="http://test.oms.jayud.com:9448/jayudFile/file/uploadFile"
              :on-success="(response)=>downTemplate(file.templateUrlVOS,response)"
              :on-error="error"
              :with-credentials="true"
              :show-file-list="false"
            >
              <el-button type="primary" size="mini">上传</el-button>
            </el-upload>
          </div>
        </div>
      </el-card>
      <el-card style="margin: 20px 0">
        <div class="jifeizhong">
          <div class="icontit">订单备注</div>
          <div class="filediv">
            <div>{{ forData.remark }}</div>
          </div>
        </div>
      </el-card>
      <el-card style="margin: 20px 0">
        <p
          style="
            font-size: 12px;
            color: #bbbbbb;
            margin: 0;
            margin-top: 20px;
          "
        >
          提货费用需要另外计算，最终以实际账单为准
        </p>
        <el-button
          type="primary"
          class="bhe32"
          style="margin-bottom: 20px"
          @click="addvisable = true"
        >选择提货地址</el-button>
        <div
          style="
            border: 1px solid #eee;
            border-radius: 5px;
            overflow: hidden;
            margin-bottom: 20px;
          "
        >
          <el-table
            class="freightRate-table center"
            :data="orderTableData"
            style="width: 100%"
          >
            <el-table-column prop="warehouseNo" label="进仓单号" />
            <el-table-column prop="pickTime" label="提货时间" width="165px">
              <template slot-scope="scope">
                <el-date-picker
                  v-model="scope.row.pickTime"
                  size="mini"
                  type="datetime"
                  class="xuanshijian"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="选择日期时间"
                />
              </template>
            </el-table-column>
            <el-table-column prop="weight" label="重量(kg)">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.weight"
                  placeholder="请输入"
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                  class="se36"
                />
              </template>
            </el-table-column>
            <el-table-column prop="volume" label="体积(m³)">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.volume"
                  placeholder="请输入"
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                  class="se36"
                />
              </template>
            </el-table-column>
            <el-table-column prop="totalCarton" label="箱数">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.totalCarton"
                  placeholder="请输入"
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                  class="se36"
                />
              </template>
            </el-table-column>
            <el-table-column prop="contacts" label="联系人" />
            <el-table-column prop="phone" label="联系电话" />
            <el-table-column prop="countryName" label="国家" />
            <el-table-column prop="stateName" label="省/州" />
            <el-table-column prop="cityName" label="城市" />
            <el-table-column prop="address" label="提货地址" />
          </el-table>
        </div>
      </el-card>
      <el-card style="margin: 20px 0">
        <div class="jifeizhong">
          <div class="icontit">集货仓库</div>
          <div class="filediv">
            <div>集货仓库：{{ forData.shippingAreaVO.warehouseName }}</div>
            <div>联系人：{{ forData.shippingAreaVO.contacts }}</div>
            <div>联系电话：{{ forData.shippingAreaVO.contactNumber }}</div>
            <div>地址：{{ forData.shippingAreaVO.addressFirst }}</div>
          </div>
        </div>
      </el-card>
      <el-card style="margin: 20px 0">
        <div class="jifeizhong">
          <div class="icontit">目的地仓库</div>
          <div class="filediv">
            <div>集货仓库：{{ forData.fabWarehouseVO.warehouseName }}</div>
            <div>联系人：{{ forData.fabWarehouseVO.contacts }}</div>
            <div>联系电话：{{ forData.fabWarehouseVO.contactNumber }}</div>
            <div>地址：{{ forData.fabWarehouseVO.addressFirst }}</div>
          </div>
        </div>
      </el-card>
      <el-card style="margin: 20px 0">
        <div class="jifeizhong">
          <div class="icontit">费用明细</div>
          <div class="tablediv">
            <el-table
              :data="forData.orderCostDetailVO.orderCopeReceivableVOS"
              stripe
              style="width: 100%"
            >
              <el-table-column prop="costName" label="费用名称" align="center" />
              <el-table-column prop="amount" label="金额" align="center" />
              <el-table-column prop="cid" label="币钟" align="center" />
              <el-table-column prop="remarks" label="备注" align="center" />
            </el-table>
            <div class="heji">
              <span class="tit">合计：</span><span>{{ forData.orderCostDetailVO.orderCopeReceivableAmountTotal }}</span>
              <!-- <br /><span>1000人民币</span> -->
            </div>
          </div>
        </div>
      </el-card>
      <div class="jifeizhong" style="text-align: center; margin-top: 10px;">
        <el-button
          type="primary"
          round
          style="width: 90px"
          @click="eidtsubimt"
        >提交</el-button>
      </div>
    </div>
    <el-dialog
      title="编辑"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <div class="piliang">
        <div class="inpdiv">
          总箱数：<el-input
            v-model="ckginfo.cartons"
            placeholder="请输入内容"
            size="mini"
            style="width: 100px"
          />
        </div>
        <div class="inpdiv">
          每箱重量(KG)：<el-input
            v-model="ckginfo.weight"
            placeholder="请输入内容"
            size="mini"
            style="width: 100px"
          />
        </div>
        <div class="inpdiv" style="width: 40%">
          每箱体积(CN)：<el-input
            v-model="ckginfo.length"
            size="mini"
            style="width: 50px"
          /><span class="dian">●</span><el-input
            v-model="ckginfo.width"
            size="mini"
            style="width: 50px"
          /><span class="dian">●</span><el-input
            v-model="ckginfo.height"
            size="mini"
            style="width: 50px"
          />
        </div>
        <div>
          <el-button
            type="primary"
            round
            size="mini"
            style="width: 90px"
            @click="piliangadd"
          >批量添加</el-button>
        </div>
      </div>
      <div class="tablediv">
        <el-table
          :data="forData.orderCaseVOList"
          stripe
          height="250"
          style="width: 100%"
        >
          <el-table-column prop="cartonNo" label="箱号" align="center" />

          <el-table-column label="FBA箱号" align="center">
            <template slot-scope="scope">
              <el-input v-model="scope.row.nameCn" size="mini" />
            </template>
          </el-table-column>
          <el-table-column label="重量(kg)" align="center">
            <template slot-scope="scope">
              <el-input v-model="scope.row.asnWeight" size="mini" />
            </template>
          </el-table-column>
          <el-table-column
            prop="meiyou"
            label="长*宽*高(cm)"
            align="center"
            width="250px"
          >
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.asnLength"
                size="mini"
                style="width: 50px"
              /><span class="dian">●</span><el-input
                v-model="scope.row.asnWidth"
                size="mini"
                style="width: 50px"
              /><span class="dian">●</span><el-input
                v-model="scope.row.asnHeight"
                size="mini"
                style="width: 50px"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="asnVolume"
            label="体积(M³)"
            align="center"
            width="100px"
          />
          <el-table-column label="Edit" align="center" width="100px">
            <template slot-scope="scope">
              <i
                class="el-icon-delete icon_i"
                @click="handleEdit(scope.row)"
              />
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="dialogVisible = false"
        >确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      width="50%"
      title="选择商品"
      :visible.sync="innerVisible"
      class="neizhidialog"
    >
      <div class="shangpingshou">
        <div>
          SKU：
          <el-input
            v-model="searchGoods.sku"
            size="mini"
            style="width: 150px"
          />
        </div>
        <div>
          商品名称(中文)：
          <el-input
            v-model="searchGoods.nameCn"
            size="mini"
            style="width: 150px"
          />
        </div>
        <div style="width: 20%">
          <el-button
            type="primary"
            round
            size="mini"
            style="width: 90px"
            @click="findCustomerGoods"
          >查询</el-button>
        </div>
      </div>
      <div class="tablediv">
        <el-table
          ref="multipleTableto"
          :data="reasonlist"
          tooltip-effect="dark"
          style="width: 100%"
          border
          height="400px"
          :row-key="getRowKey"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            width="55"
            :reserve-selection="true"
          />
          <el-table-column prop="sku" label="SKU" :show-overflow-tooltip="true" />
          <el-table-column
            prop="nameCn"
            label="商品名称(中文)"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            prop="types"
            label="商品类型"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            prop="declaredValue"
            label="申报价值(USD)"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            prop="hsCode"
            label="海关编码"
            :show-overflow-tooltip="true"
          />
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="innerVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="xuanzhequeren"
        >确 定</el-button>
      </span>
    </el-dialog>
    <SelectAddress
      :visible="addvisable"
      @sublime="submitAddress"
      @close="addvisable = false"
    />
  </div>
</template>

<script>
import SelectAddress from '@/components/SelectAddress';
import {
  createOrderNum
} from '@/api/freight.js';
import {
  orderdateils,
  findCustomerGoodsByPage,
  createOrderCaseList,
  editSaveOrderInfo
} from '@/api/order.js';
export default {
  name: 'Editorder',
  components: {
    SelectAddress
  },
  props: {},
  data: function() {
    return {
      forData: {
        shippingAreaVO: {},
        fabWarehouseVO: {},
        orderCostDetailVO: {},
        frontStatusCode: ''
      },
      ckginfo: {
        cartons: '',
        weight: '',
        length: '',
        width: '',
        height: ''
      },
      fileList: [],

      input: '',
      dialogVisible: false,
      innerVisible: false,
      reasonCodeIds: [],
      reasonlist: [],
      addvisable: false, // 选择地址
      orderTableData: [], // 提货地址table
      tableData: [
        {
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        },
        {
          date: '2016-05-04',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1517 弄'
        },
        {
          date: '2016-05-01',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1519 弄'
        },
        {
          date: '2016-05-03',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1516 弄'
        }
      ],
      selectedGoods: [],
      searchGoods: {}
    };
  },
  watch: {},
  created() {
    this.getorderinfo();
  },
  activated() {
    // 避免缓存及设置
    if (!this.$route.meta.isBack) {
      this.getorderinfo();
    }
    this.$route.meta.isBack = false;
  },
  mounted() {},
  methods: {
    // 删除文件
    fileRemove(obj, i) {
      console.log(obj);
      obj.splice(i, 1);
    },
    getRowKey(row) {
      return row.id;
    },
    // 列表勾选的数据
    handleSelectionChange(val) {
      this.reasonCodeIds = val;
    },
    xuanzhequeren() {
      this.innerVisible = false;
      for (var i in this.reasonCodeIds) {
        this.forData.orderShopVOList.push(this.reasonCodeIds[i]);
      }
    },
    // 选择地址
    submitAddress(row) {
      this.createOrderNum(row);
    },
    // 请求单号
    createOrderNum(obj) {
      createOrderNum(obj).then((result) => {
        if (result.status == 200) {
          this.orderTableData = result.data.data;
          if (this.orderTableData.length > 0) {
            this.orderTableData[0].weight = this.zongzong;
            this.orderTableData[0].totalCarton = this.zongshu;
            this.orderTableData[0].volume = this.zongti;
          }
        }
      });
    },
    getorderinfo() {
      orderdateils({ id: this.$route.query.id })
        .then((result) => {
          if (result.data.code == 0) {
            console.log(result.data);
            this.forData = result.data.data;
          }
        })
        .catch((error) => {
          try {
            if (error.response) {
              this.$message.error({
                message: error.response.data.msg
              });
            } else {
              this.$message.error({
                message: '请求失败'
              });
            }
          } catch (err) {
            this.$message.error({
              message: err
            });
          }
        });
    },
    findCustomerGoods() {
      this.searchGoods.status = 1;
      findCustomerGoodsByPage({ pageNum: 1, pageSize: 20, ...this.searchGoods }).then((result) => {
        if (result.data.code == 0) {
          this.reasonlist = result.data.data.list;
        }
      });
    },
    skutitdetails(id) {
      this.$router.push({
        name: 'commoditydetails',
        query: {
          id: id
        }
      });
    },
    handleClose() {},
    xuanzheshangping() {
      const filterGoodIds = [];
      if (this.forData.orderShopVOList.length) {
        this.forData.orderShopVOList.forEach(item => {
          if (item.goodId) {
            if (filterGoodIds.indexOf(item.goodId) === -1) {
              filterGoodIds.push(item.goodId);
            }
          } else {
            if (filterGoodIds.indexOf(item.id) === -1) {
              filterGoodIds.push(item.id);
            }
          }
        });
      }
      findCustomerGoodsByPage({ pageNum: 1, pageSize: 20, status: 1, filterGoodIds }).then((result) => {
        if (result.data.code == 0) {
          this.reasonlist = result.data.data.list;
          this.innerVisible = true;
        }
      });
    },
    piliangadd() {
      createOrderCaseList(this.ckginfo).then((result) => {
        if (result.data.code == 0) {
          for (var i in result.data.data) {
            this.forData.orderCaseVOList.push(result.data.data[i]);
          }
        }
      });
    },
    eidtsubimt() {
      // submitOrder({}).then(res => {

      // })
      editSaveOrderInfo({
        id: this.$route.query.id,
        orderCaseVOList: this.forData.orderCaseVOList,
        orderPickVOList: this.orderTableData,
        orderShopVOList: this.forData.orderShopVOList,
        orderCustomsFileVOList: this.forData.orderCustomsFileVOList,
        orderClearanceFileVOList: this.forData.orderClearanceFileVOList
      }).then((result) => {
        if (result.data.code == 0) {
          this.$message({
            message: '成功',
            duration: 1500,
            type: 'success'
          });
          this.$router.push({
            name: 'orderlist'
          });
        } else {
          this.$message({
            message: result.data.msg,
            type: 'error'
          });
        }
      });
    },
    // 表格列表上传成功操作
    downTemplate(obj, response) {
      console.log(obj);
      if (response.code == 200) {
        obj.push(response.data);
        this.$message({
          message: '上传成功',
          duration: 1500,
          type: 'success'
        });
      } else {
        this.$message({
          message: response.msg,
          duration: 1500,
          type: 'error'
        });
      }
    },
    // 上传失败
    error() {
      this.load = false;
      this.$message({
        message: '上传失败',
        duration: 1500,
        type: 'error'
      });
    },
    handleUpSuccess(obj, resp) {
      console.log(obj);
      console.log(resp);
    },
    reTime(time) {
      // 时间格式重置
      if (time) {
        const str = time.split(' ');
        return str[0];
      } else {
        return '';
      }
    },
    handleSelectGoods(val) {
      this.selectedGoods = val;
    },
    deleteGoods() {
      for (var i = 0; i < this.selectedGoods.length; i++) {
        for (var j = 0; j < this.forData.orderShopVOList.length; j++) {
          if (this.selectedGoods[i].id === this.forData.orderShopVOList[j].id) {
            this.forData.orderShopVOList.splice(j, 1);
          }
        }
      }
    }
  }
};
</script>

<style scoped>
.item {
  margin-bottom: 0px;
  background-color: #ecf5ff;
  border-color: #d9ecff;
  display: inline-block;
  height: 32px;
  padding: 0 10px;
  line-height: 30px;
  font-size: 12px;
  color: #409eff;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  box-sizing: border-box;
  white-space: nowrap;
  margin-right: 10px;
}
.item a {
  text-decoration-line: none;
  color: #007aff;
}
.danhao {
  width: 100%;
  height: 40px;
  background: #f3f4f9;
  border-radius: 4px;
  display: flex;
}
.danhao div {
  width: 50%;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
}
.jifeizhong {
  padding-bottom: 10px;
  border-bottom: 5px solid #eeeeee;
}
.jifeizhong .danhao div {
  font-size: 14px;
}
.infodiv {
  width: 100%;
  height: auto;
  display: flex;
  border-bottom: 5px solid #eeeeee;
}
.xinxi {
  width: 100%;
}

.icontit {
  line-height: 40px;
  width: 100%;
  border-bottom: 1px solid #eeeeee;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
}
.xinxifo {
  width: 100%;
  /* height: 40px; */
  display: flex;
}
.xinxifo div {
  width: 25%;
  text-align: left;
  text-indent: 10px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 40px;
}
.tablediv {
  width: 100%;
  border: 1px solid #eeeeee;
  margin-top: 10px;
}
.filediv {
  display: flex;
}
.filediv div {
  width: 50%;
  text-align: left;
  line-height: 40px;
  text-indent: 10px;
}
.heji {
  padding-right: 20px;
  text-align: right;
  height: 45px;
  padding-top: 10px;
}
.heji span {
  color: red;
}
.heji .tit {
  color: #333333;
  font-weight: bold;
}
.queren {
  padding-right: 10px;
  text-align: right;
  line-height: 50px;
}
.filelist {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}
.filelist div {
  width: 33%;
}
.skutit {
  cursor: pointer;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #507eff;
}
.upload {
  margin-top: 20px;
  display: flex;
}
.piliang {
  width: 100%;
  height: 40px;
  display: flex;
}
.inpdiv {
  width: 25%;
}
.dian {
  margin-left: 5px;
  margin-right: 5px;
}
.icon_i {
  color: red;
  font-size: 16px;
  cursor: pointer;
}
.shangpingshou {
  width: 100%;
  display: flex;
}
.shangpingshou div {
  width: 40%;
}
.filespan{
  margin-right: 20px;
    color: blue;
}
</style>
